import iconifyIconOffline from './src/iconifyIconOffline'
import iconifyIconOnline from './src/iconifyIconOnline'
import fontIcon from './src/iconfont'

/** 本地图标组件 */
const IconifyIconOffline = iconifyIconOffline
/** 在线图标组件 */
const IconifyIconOnline = iconifyIconOnline
/** iconfont组件 */
const FontIcon = fontIcon

export { IconifyIconOffline, IconifyIconOnline, FontIcon }
